
import {Component} from 'vue-property-decorator';
import {departmentsListHeaders} from '@/modules/departments/shared/config';
import DepartmentDetails from '@/modules/departments/components/details.vue';
import DepartmentForm from '@/modules/departments/components/form.vue';
import ListComponent from '@/shared/components/layout/list/list-component';
import {DepartmentDataRequest} from '@/modules/departments/shared/requests/department-data-request';
import {mdiHome} from '@mdi/js';
import {departmentsModule} from '@/modules/departments/shared/state/module';
import {Department} from '@/modules/departments/shared/models/department';
import ItemsTable from '@/shared/components/layout/list.vue';
import {ModuleProps} from '@/shared/state/template/module-props';

@Component({
    components: {
        DepartmentDetails,
        DepartmentForm,
        ItemsTable,
    },
})
export default class DepartmentsList extends ListComponent<Department, DepartmentDataRequest> {
    public headers: object[] = departmentsListHeaders;
    public actionsTypes = departmentsModule.actionsTypes;
    public mutationTypes = departmentsModule.mutationsTypes;
    public props: ModuleProps = departmentsModule.moduleProps;
    public fetchAction = this.actionsTypes.FETCH_DATA;
    public removeAction = this.actionsTypes.REMOVE_ITEM;
    public store = this.$store.state.departmentsState;
    public itemTemplate = {country: 'Polska'} as Department;
    public icons: object = {
        mdiHome,
    };

    get mainWarning(): string {
        return this.store.current && this.store.current.main ? 'Próbujesz usunąć główny oddział' : '';
    }
}
