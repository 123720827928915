
    import {Component} from 'vue-property-decorator';
    import {departmentsModule} from '@/modules/departments/shared/state/module';
    import {mdiEmail, mdiPhone} from '@mdi/js';
    import AddressField from '@/shared/components/elements/address.vue';
    import DetailsComponent from '@/shared/components/layout/details/details-component';
    import {DepartmentDataRequest} from '@/modules/departments/shared/requests/department-data-request';
    import TitleBar from '@/shared/components/layout/details/title-bar.vue';
    import {ModuleProps} from '@/shared/state/template/module-props';

    @Component({
        props: {
            id: String,
        },
        components: {
            TitleBar,
            AddressField,
        },
    })
    export default class DepartmentDetails extends DetailsComponent<DepartmentDataRequest> {
        public icons: object = {
            mdiPhone,
            mdiEmail,
        };
        public props: ModuleProps = departmentsModule.moduleProps;
        public actionsTypes = departmentsModule.actionsTypes;
        public showAction = this.actionsTypes.SHOW_ITEM;
        public store = this.$store.state.departmentsState;

        public created() {
            // this.fetchDetails();
        }
    }
